// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/lukewalker/Documents/Luke&Phil/MarketingSite/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-js": () => import("/Users/lukewalker/Documents/Luke&Phil/MarketingSite/src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-index-js": () => import("/Users/lukewalker/Documents/Luke&Phil/MarketingSite/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-js": () => import("/Users/lukewalker/Documents/Luke&Phil/MarketingSite/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-websites-js": () => import("/Users/lukewalker/Documents/Luke&Phil/MarketingSite/src/pages/websites.js" /* webpackChunkName: "component---src-pages-websites-js" */),
  "component---src-pages-what-we-do-js": () => import("/Users/lukewalker/Documents/Luke&Phil/MarketingSite/src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */)
}

